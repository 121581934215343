
import BookCarousel from './BookCarousel.vue'
import bookService from '@/app/services/bookService.js';

export default ({
  components: {BookCarousel},
  computed: {
    collections() {
      return bookService.getRecommendations();
    },
    genreCollections() {
      return bookService.getRecommendations().genreCollections || [];
    },
    hookBookCollections() {
      return bookService.getRecommendations().hookBookCollections || [];
    }
  },
  methods: {
    formatGenreHeader(i) {
      return this.$t('carousel.becauseYouLike', {genre: this.$t("genres." + i.replace(/\./g, ""))});
    },
    determineCardColor(i) {
      switch (i) {

          /* Verify correct spanish abbreviations */

        case 'y':
          // case 'am':
          return 'rgba(255, 204, 0, 0.2)';
        case 'g':
        case '1g':
        case '2g':
          // case '1v':
          // case '2v':
          // case 'tu':
          return 'rgba(57, 181, 74, 0.2)';
        case 'b':
        case '1b':
        case '2b':
          // case 'az':
          // case '1az':
          // case '2az':
          return 'rgba(0, 114, 188, 0.2)';
        case 'r':
        case '1r':
        case '2r':
          return 'rgba(229, 0, 0, 0.2)';
        case 'wt':
        case 'bl':
          return 'rgba(223, 223, 223, 0.2)';
        case 'bk':
        case 'ne':
          return 'rgba(170, 170, 170, 0.2)';
        case 'or':
        case 'an':
          return 'rgba(255, 153, 51, 0.2)';
        case 'pu':
          return 'rgba(102, 51, 153, 0.2)';
        case 'br':
          return 'rgba(194, 117, 20, 0.2)';
        case 'si':
          return 'rgba(196, 201, 204, 0.2)';
        case 'gl':
          return 'rgba(254, 195, 90, 0.2)';
        default:
          return 'transparent';
      }
    }
  }
})

