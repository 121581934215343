import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "carousels" }
const _hoisted_2 = {
  key: 0,
  class: "empty-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_book_carousel = _resolveComponent("book-carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.$t('carousel.title')), 1),
    ($options.genreCollections.length === 0 && $options.hookBookCollections.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Start reading for book recommendations. "))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.hookBookCollections, (collection, index) => {
      return (_openBlock(), _createBlock(_component_book_carousel, {
        class: "mx-auto",
        key: index,
        title: _ctx.$t('carousel.hookBooks'),
        backgroundColor: $options.determineCardColor($options.hookBookCollections[index].name.split(' ')[0].toLowerCase()),
        badge: $options.hookBookCollections[index].name.split(' ')[0].toUpperCase(),
        bookRouteBooks: $options.hookBookCollections[index].books
      }, null, 8, ["title", "backgroundColor", "badge", "bookRouteBooks"]))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.genreCollections, (collection, index) => {
      return (_openBlock(), _createBlock(_component_book_carousel, {
        class: "mx-auto",
        key: index,
        title: $options.formatGenreHeader($options.genreCollections[index].name),
        backgroundColor: $options.determineCardColor($options.genreCollections[index]),
        bookRouteBooks: $options.genreCollections[index].books
      }, null, 8, ["title", "backgroundColor", "bookRouteBooks"]))
    }), 128))
  ]))
}