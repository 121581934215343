
import {computed, PropType} from 'vue';
import ValidationError from "@/app/components/ValidationError.vue";

export default {
  components: {ValidationError},
  props: {
    errors: {},
    modelValue: {
      type: Object as PropType<any>, // Type Annotation
      default: () => ({}),
    },
  },
  data() {
    let date = new Date();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    return {
      maxDate: date.getFullYear() + "-" + month + "-" + day
    }
  },
  methods: {
    required(fieldName) {
      return this.errors[fieldName] != null ? 'invalid' : '';
    },
    updateValue(event) {
      this.$emit('update:modelValue', event.target.value);
    }
  },
  setup(props, {emit}) {
    const theModel = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    return {theModel};
  }

}
