
import Carousel from "@/core/components/Carousel.vue";
import BookDetail from "@/app/components/BookList/BookDetail.vue";

export default {
  props: ['heading', 'books', 'modelValue', 'emptyText'],
  components: {
    BookDetail,
    Carousel
  },
  data() {
    return {}
  },
  computed: {
    bookCount(): number {
      return this.books ? this.books.length : 0;
    }
  },
  methods: {
    slideTo(slide) {
      this.currentSlide = slide;
      this.$refs.carousel.slideTo(slide);
    }
  }
}
