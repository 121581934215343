import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "book-rating" }
const _hoisted_2 = {
  key: 0,
  class: "label"
}
const _hoisted_3 = {
  key: 1,
  class: "label"
}
const _hoisted_4 = ["onMouseover", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.rating == null)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('ratings.rateThisBook')), 1))
      : _createCommentVNode("", true),
    ($setup.rating != null)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('ratings.yourRating')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "stars",
      onMouseout: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.mouseOut && $options.mouseOut(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.range, (i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: _normalizeClass(["star", 'star-'+$options.cssClass(i)]),
          onMouseover: ($event: any) => ($options.mouseOver(i)),
          onClick: ($event: any) => ($options.setRating(i))
        }, null, 42, _hoisted_4))
      }), 128))
    ], 32)
  ]))
}