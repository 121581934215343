import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "carousel-body" }
const _hoisted_2 = { class: "carousel-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("ul", {
          ref: "carousel",
          onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.mouseDownHandler && _ctx.mouseDownHandler(...args))),
          onMouseup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.mouseUpHandler && _ctx.mouseUpHandler(...args))),
          class: _normalizeClass({ 'deactivated': _ctx.atEnd && _ctx.atStart, 'grabbing': _ctx.grabbing })
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 34)
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["carousel-navigation", { 'deactivated': _ctx.atEnd && _ctx.atStart }])
    }, [
      _createVNode(_component_inline_svg, {
        onClick: _ctx.goToStart,
        class: _normalizeClass(["nav-chevron double", { 'deactivated': _ctx.atStart }]),
        src: "/media/icons/chevrons-left-solid.svg",
        cypressTest: "skipToStart"
      }, null, 8, ["onClick", "class"]),
      _createVNode(_component_inline_svg, {
        onClick: _ctx.scrollPrev,
        class: _normalizeClass(["nav-chevron single", { 'deactivated': _ctx.atStart }]),
        src: "/media/icons/chevron-left-solid.svg"
      }, null, 8, ["onClick", "class"]),
      _createVNode(_component_inline_svg, {
        onClick: _ctx.scrollNext,
        class: _normalizeClass(["nav-chevron single", { 'deactivated': _ctx.atEnd }]),
        src: "/media/icons/chevron-right-solid.svg"
      }, null, 8, ["onClick", "class"]),
      _createVNode(_component_inline_svg, {
        onClick: _ctx.goToEnd,
        class: _normalizeClass(["nav-chevron double", { 'deactivated': _ctx.atEnd }]),
        src: "/media/icons/chevrons-right-solid.svg",
        cypressTest: "skipToEnd"
      }, null, 8, ["onClick", "class"])
    ], 2)
  ], 64))
}