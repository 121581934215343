
import {Modal} from "bootstrap";
import BookSummary from "@/app/components/BookSummary.vue";
import moment from 'moment-with-locales-es6';
import logService from "@/app/services/LogService";
import bookService from "@/app/services/bookService";


/**
 * Not working - could not work out z-index issues from layered / nested modals.
 */
export default {
  components: {BookSummary},
  props: ['show-button'],
  data() {
    return {
      book: null,
      showDeleteConfirmation: false,
      entryToDelete: null,
      showPausedText: false
    }
  },
  computed: {
    startOfReading() {
      if (this.book != null) {
        let m = moment(this.book.log_entries[this.book.log_entries.length - 1]?.entry_date);
        m.locale(this.$i18n.locale);
        return m.format("LL");
      }
      return this.$t("global.na");
    },

  },
  methods: {
    toolTipText(entry) {
      return this.$t("global.status." + (entry.review_status == 'Submitted' ? 'Reviewing' : entry.review_status));
    },
    canDelete(entry) {
      if(this.book?.book && this.book?.book.bookshelf_sku !== null){
        return false
      } else if (entry.id !== null && entry.review_status != 'Approved'){
        return true
      } else {
        return false
      }
      // return entry.id != null && entry.review_status != 'Approved' && this.book?.book.bookshelf_sku !== null;
    },
        hasAccess(book) {
      return bookService.canLinkToBookshelf(book.book.bookshelf_sku);
    },
    deleteConfirmation(entry) {
      this.showDeleteConfirmation = true;
      this.entryToDelete = entry;
    },
    deleteEntry() {
      /* If user is deleting the last entry, close modals and set book status to 'Paused' */
      if (this.book?.log_entries && this.book?.log_entries.length === 1) {
        this.showPausedText = true;
          this.showDeleteConfirmation = false;
          logService.setStatus(this.book, "Paused")
          this.closeDialog();
      } else {
        this.showDeleteConfirmation = false;
      }
      if (!this.canDelete(this.entryToDelete)) return;
      return logService.deleteEntry(this.book, this.entryToDelete);
    },
    closeDeleteConfirmation() {
      this.showDeleteConfirmation = false;
    },
    dateFormat(date) {
      return moment(date).locale(this.$i18n.locale).format('LL');
    },
    openDialog(book = null) {
      this.book = book;
      this.dialog = new Modal(this.$refs.viewLogModal);
      this.dialog.show();
    },
    closeDialog() {
      this.showDeleteConfirmation = false;
      if (this.dialog)
        this.dialog.hide();
    },
  }
}
