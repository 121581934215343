import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a5e819b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view-log-modal" }
const _hoisted_2 = {
  class: "modal fade",
  ref: "bookshelfBookModal",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_3 = { class: "modal-dialog" }
const _hoisted_4 = { class: "modal-content" }
const _hoisted_5 = { class: "modal-header" }
const _hoisted_6 = { class: "modal-title" }
const _hoisted_7 = ["aria-label"]
const _hoisted_8 = { class: "modal-body" }
const _hoisted_9 = { class: "modal-footer" }
const _hoisted_10 = {
  "data-bs-dismiss": "modal",
  class: "cancel-bttn"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_book_summary = _resolveComponent("book-summary")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#modal-container" }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h5", _hoisted_6, _toDisplayString(_ctx.$t('modals.bookshelf.title')), 1),
              _createElementVNode("button", {
                type: "button",
                class: "btn-close",
                "data-bs-dismiss": "modal",
                "aria-label": _ctx.$t('global.closeButton')
              }, null, 8, _hoisted_7)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_book_summary, { book: $data.book }, null, 8, ["book"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('global.cancelButton')), 1),
              _createElementVNode("button", {
                type: "button",
                class: "me-2 mt-2 btn bttn-addsteps btn-shadow",
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.addSteps($data.book)))
              }, [
                _createVNode(_component_inline_svg, {
                  src: "/media/icons/blue-plus-icon.svg",
                  class: "plus-icon"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('bookDetail.addSteps')), 1)
              ]),
              _createElementVNode("button", {
                "data-bs-dismiss": "modal",
                type: "button",
                class: "btn start-reading-bttn",
                onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.openBook($data.book)))
              }, [
                _createVNode(_component_inline_svg, {
                  src: require('/public/media/icons/bookshelf-icon.svg'),
                  style: {width: '18px', height: '18px', marginRight: '4px'}
                }, null, 8, ["src"]),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('modals.bookshelf.startReading')), 1)
              ])
            ])
          ])
        ])
      ], 512)
    ])
  ]))
}