
import {Modal} from "bootstrap";
import BookSummary from "@/app/components/BookSummary.vue";
import authenticationService from "@/core/services/AuthenticationService";

export default {
  data() {
    return {
      bookInfo: null,
      version: '2022.05.23',
      confettiSrc: null
    }
  },
  computed: {
    firstName() {
      let user = authenticationService.currentUser();
      if (user && user.fullName) {
        return user.fullName.split(' ')[0]
      }
      return '';
    },
  },
  components: {BookSummary},
  methods: {
    openDialog(book = null) {
      this.bookInfo = book;
      this.dialog = new Modal(this.$refs.modalDialog);
      this.dialog.show();
      this.showConfetti();
    },
    showConfetti(){
      this.confettiSrc = '/media/gifs/confetti.gif';
      setTimeout(()=>{
        this.confettiSrc = null;
      }, 4000)
    },
    closeDialog() {
      if (this.dialog)
        this.dialog.hide();
    }
  }
}
