
import {Modal} from "bootstrap";
import ValidationError from "@/app/components/ValidationError.vue";
import logService from "@/app/services/LogService";

export default {
  props: ['title'],
components: {ValidationError},
  data() {
    return {
    errors: {},
      book: {
        title: null,
        author: null,
        isbn: null,
        numberOfPages: null
      },
    }
  },
  methods: {
    openDialog(book) {
      this.book = book;
      this.dialog = new Modal(this.$refs.editBookModal);
      this.dialog.show();
    },
    required(fieldName) {
      return this.errors[fieldName] != null ? 'invalid' : '';
    },
    saveForm() {
        this.errors = {};
        if (this.book.title === '') {
            this.errors.title = 'Required: What is the book title?';
        }
        if (this.book.author === '') {
            this.errors.author = 'Required: Who is the author?';
        }

        let errors = Object.keys(this.errors).length
        if (errors > 0){
            console.log('ping');
            return
        };

        logService.saveBook(this.book);
        this.closeDialog();
    },
    closeDialog() {
      if (this.dialog)
        this.dialog.hide();
    }
  }
}
