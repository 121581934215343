import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2db033d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "carousel-wrapper position-relative" }
const _hoisted_2 = { class: "header-text" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "book-info" }
const _hoisted_5 = { class: "book-title" }
const _hoisted_6 = { class: "book-author" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_level_badge = _resolveComponent("level-badge")!
  const _component_book_cover = _resolveComponent("book-cover")!
  const _component_Carousel = _resolveComponent("Carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "carousel-header",
      style: _normalizeStyle({backgroundColor: $props.backgroundColor, borderRadius: $data.borderRadius})
    }, [
      _createVNode(_component_inline_svg, {
        src: require('/public/media/icons/expand-arrow.svg'),
        onClick: $options.expand,
        class: "expand-arrow",
        style: _normalizeStyle({transform: $data.arrowDirection})
      }, null, 8, ["src", "onClick", "style"]),
      ($props.badge)
        ? (_openBlock(), _createBlock(_component_level_badge, {
            key: 0,
            class: _normalizeClass(["temporary-level-badge", `level-${$props.badge}`]),
            level: $props.badge
          }, null, 8, ["class", "level"]))
        : _createCommentVNode("", true),
      _createElementVNode("p", _hoisted_2, _toDisplayString($props.title), 1)
    ], 4),
    _createElementVNode("div", {
      class: "carousel-row",
      style: _normalizeStyle({ backgroundColor: $props.backgroundColor, display: $data.carouselDisplay })
    }, [
      _createVNode(_component_Carousel, { snap: 10 }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.bookRouteBooks, (book, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: "book",
              onClick: ($event: any) => ($options.openBook(book))
            }, [
              _createVNode(_component_book_cover, {
                class: "book-img",
                book: book,
                bookLevel: book.level
              }, null, 8, ["book", "bookLevel"]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("p", _hoisted_5, _toDisplayString($options.formatTitle(book.title)), 1),
                _createElementVNode("p", _hoisted_6, _toDisplayString(book.author), 1)
              ])
            ], 8, _hoisted_3))
          }), 128))
        ]),
        _: 1
      })
    ], 4)
  ]))
}