
import {Modal} from "bootstrap";
import BookSummary from "@/app/components/BookSummary.vue";
import LevelBadge from "@/app/components/LevelBadge.vue";
import bookService from "@/app/services/bookService";
import eventBus from "@/core/services/eventBus";

/**
 * Not working - could not work out z-index issues from layered / nested modals.
 */
export default {
  components: {BookSummary, LevelBadge},
  data() {
    return {
      book: null,
    }
  },
  methods: {
    openDialog(book = null) {
      this.book = book;
      this.dialog = new Modal(this.$refs.bookshelfBookModal);
      this.dialog.show();
    },
    openBook(book) {
        let url = bookService.bookshelfURL(book.bookshelf_sku);
        window.open(url.toString());
    },
    addSteps(book) {
      this.closeDialog();
      eventBus.$emit("logReading", book);
    },
    closeDialog() {
      if (this.dialog)
        this.dialog.hide();
    }
  }
}
