import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "book-list" }
const _hoisted_2 = {
  key: 0,
  class: "book-count"
}
const _hoisted_3 = {
  key: 0,
  class: "empty-text"
}
const _hoisted_4 = {
  key: 1,
  style: {"position":"relative"}
}
const _hoisted_5 = { class: "carousel__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_book_detail = _resolveComponent("book-detail")!
  const _component_Carousel = _resolveComponent("Carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createTextVNode(_toDisplayString($props.heading) + " ", 1),
      ($options.bookCount > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "(" + _toDisplayString($options.bookCount) + ")", 1))
        : _createCommentVNode("", true)
    ]),
    ($options.bookCount <= 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($props.emptyText), 1))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_Carousel, { snap: 100 }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.books, (book, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: book,
                  class: "bookList-book"
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_book_detail, {
                      book: book,
                      index: index,
                      bookCount: $options.bookCount
                    }, null, 8, ["book", "index", "bookCount"])
                  ])
                ]))
              }), 128))
            ]),
            _: 1
          })
        ]))
  ]))
}