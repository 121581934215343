import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "reading-complete-modal" }
const _hoisted_2 = {
  class: "modal fade",
  ref: "modalDialog",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_3 = { class: "modal-dialog" }
const _hoisted_4 = { class: "modal-content" }
const _hoisted_5 = { class: "modal-body position-relative" }
const _hoisted_6 = { class: "mb-3" }
const _hoisted_7 = { class: "card" }
const _hoisted_8 = { class: "card-body card-green" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "modal-footer" }
const _hoisted_11 = {
  type: "button",
  class: "btn btn-primary",
  "data-bs-dismiss": "modal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_book_summary = _resolveComponent("book-summary")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#modal-container" }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t('modals.readingComplete.greatWork', {name: $options.firstName})), 1),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_book_summary, { book: $data.bookInfo }, null, 8, ["book"])
                ])
              ]),
              _createElementVNode("img", {
                class: "confetti-gif position-absolute w-100 h-100",
                src: $data.confettiSrc,
                style: _normalizeStyle({display: $data.confettiSrc !== null ? 'block' : 'none'})
              }, null, 12, _hoisted_9)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("button", _hoisted_11, _toDisplayString(_ctx.$t('global.closeButton')), 1)
            ])
          ])
        ])
      ], 512)
    ])
  ]))
}