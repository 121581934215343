
import { defineComponent } from "vue";

export default defineComponent({
    name: "Carousel",
    props: {
        snap: {
            type: Number || undefined,
            default: undefined
        }
    },
    data() {
        return {
            atEnd: false,
            atStart: true,
            dx: 0,
            grabbing: false,
            observers: {
                mutation: null,
                resize: null,
            },
            onscrollend: 'onscrollend' in window,
            pos: {
                top: 0,
                left: 0,
                x: 0,
                y: 0
            },
            scrollBehavior: 'smooth',
            scrollEndTimer: null,
            scrolling: false,
            snapping: false,
            threshold: 10
        }
    },
    methods: {
        checkPos() {
            if (this.$refs.carousel.scrollLeft <= 0) {
                this.atStart = true;
            } else {
                this.atStart = false;
            }
            if (this.$refs.carousel.scrollWidth - this.$refs.carousel.offsetWidth <= Math.round(this.$refs.carousel.scrollLeft)) {
                this.atEnd = true;
            } else {
                this.atEnd = false;
            }
        },
        handleScroll() {
            if (!this.onscrollend) {
                // This is the best that can be done for browsers that don't support the new scrollend event yet
                clearTimeout(this.scrollEndTimer);
                this.scrollEndTimer = setTimeout(this.handleScrollEnd, 50);
            }
        },
        handleScrollEnd() {
            this.scrolling = false;
            this.snapping = false;
            this.checkPos();
        },
        mouseDownHandler(e: MouseEvent) {
            this.pos = {
                // The current scroll
                left: this.$refs.carousel.scrollLeft,
                // Get the current mouse position
                x: e.clientX
            }
            this.$refs.carousel.addEventListener('mousemove', this.mouseMoveHandler);
            this.$refs.carousel.addEventListener('mouseleave', this.mouseUpHandler);
        },
        mouseMoveHandler(e: MouseEvent) {
            // How far the mouse has been moved
            this.dx = e.clientX - this.pos.x;
            if (Math.abs(this.dx) > 5 && (this.atEnd === false || this.atStart === false)) this.grabbing = true;
            // Scroll the element
            this.$refs.carousel.scrollTo({
                left: this.pos.left - this.dx
            })
        },
        mouseUpHandler() {
            this.$refs.carousel.removeEventListener('mousemove', this.mouseMoveHandler);
            this.$refs.carousel.removeEventListener('mouseleave', this.mouseUpHandler);
            if (this.grabbing && typeof this.snap !== 'undefined') {
                this.snapping = true;
                if (this.dx < 0) {
                    if (this.dx > -this.snap && (this.$refs.carousel.scrollLeft + this.$refs.carousel.clientWidth < this.$refs.carousel.scrollWidth)) {
                        this.scrollPrev();
                    }
                    else {
                        if (this.pos.left < this.$refs.carousel.scrollLeft) {
                            this.scrollNext();
                        }
                    }
                }
                else if (this.dx > 0) {
                    if (this.dx > this.snap) {
                        this.scrollPrev();
                    }
                    else {
                        if (this.$refs.carousel.scrollLeft > 0 && this.pos.left > this.$refs.carousel.scrollLeft) {
                            this.scrollNext();
                        }
                    }
                }
            }
            setTimeout(() => {
                this.grabbing = false;
            }, 1)
        },
        scrollPrev() {
            const children = this.$refs.carousel.querySelectorAll('li');
            for (const child of children) {
                const styles = getComputedStyle(child);
                const forgiveness = (this.snapping) ? 0 : this.threshold;
                if (this.$refs.carousel.scrollLeft === child.offsetLeft) {
                    if (child.previousElementSibling) {
                        this.scroll(child.previousElementSibling.offsetLeft);
                    }
                    break;
                }
                else {
                    if (this.$refs.carousel.scrollLeft < child.offsetLeft + child.offsetWidth + parseInt(styles.getPropertyValue('margin-right')) + forgiveness) {
                        this.scroll(child.offsetLeft);
                        break;
                    }
                }
            }
        },
        scrollNext() {
            const children = this.$refs.carousel.querySelectorAll('li');
            for (const child of children) {
                const styles = getComputedStyle(child);
                const forgiveness = (this.snapping) ? 0 : this.threshold;
                if (child.offsetLeft + child.offsetWidth + parseInt(styles.getPropertyValue('margin-right')) - forgiveness > this.$refs.carousel.clientWidth + this.$refs.carousel.scrollLeft) {
                    if (!child.nextElementSibling) {
                        this.goToEnd();
                    }
                    else {
                        this.scroll(this.$refs.carousel.scrollLeft + child.offsetWidth - (this.$refs.carousel.clientWidth + this.$refs.carousel.scrollLeft - child.offsetLeft) + parseInt(styles.getPropertyValue('margin-right')));
                    }
                    break;
                }
            }
        },
        goToStart() {
            this.scroll(0);
        },
        goToEnd() {
            this.scroll(this.$refs.carousel.scrollWidth)
        },
        scroll(pos: Number) {
            if (!this.scrolling || this.snapping) {
                this.scrolling = true;
                this.$refs.carousel.scrollTo({
                    left: pos,
                    behavior: this.scrollBehavior
                });
            }
        }
    },
    mounted() {
        // TODO: Install @types/resize-observer-browser and add to tsconfig?
        this.$refs.carousel.style.position = 'relative';
        this.$refs.carousel.addEventListener('scroll', this.handleScroll);
        this.$refs.carousel.addEventListener('scrollend', this.handleScrollEnd);
        this.observers.resize = new (window as any).ResizeObserver((entries: Array<HTMLElement>) => {
            for (const _entry of entries) {
                if (this.$refs.carousel) {
                    this.checkPos();
                }
            }
        });
        this.observers.resize.observe(this.$refs.carousel);
        const mutationConfig = { childList: true, subtree: true };
        // TODO: Find types for MutationObserver and add to tsconfig?
        this.observers.mutation = new (window as any).MutationObserver((entries: Array<HTMLElement>) => {
            for (const _entry of entries) {
                if (this.$refs.carousel) {
                    this.checkPos();
                }
            }
        });
        this.observers.mutation.observe(this.$refs.carousel, mutationConfig);
        this.checkPos();
    },
    beforeUnmount() {
        this.$refs.carousel.removeEventListener('scroll', this.handleScroll);
        this.$refs.carousel.removeEventListener('scrollend', this.handleScrollEnd);
        this.observers.mutation.disconnect();
        this.observers.resize.disconnect();
    }
});
