
import StepsForm from "@/app/components/StepsForm.vue";
import {Modal} from "bootstrap";
import logService from "@/app/services/LogService";
import BookSummary from "@/app/components/BookSummary.vue";
import ValidationError from "@/app/components/ValidationError.vue";
import eventBus from "@/core/services/eventBus";

/**
 * Not working - could not work out z-index issues from layered / nested modals.
 */
export default {
  components: {ValidationError, BookSummary, StepsForm},
  props: ['show-button'],
  mounted() {
    this.resetForm();
  },
  data() {
    return {
      errors: {},
      counter: 0,
      book_instance: {},
      isLoading: false,
      formData: {
        title: null,
        author: null,
        isbn: null,
        book_id: null,
        number_of_pages: null,
        steps: null,
        entry_date: null,
        page_number: null
      }
    }
  },
  computed: {
    showPageCountInput() {
      // Not for bookshelf books
      if (this.book_instance.book != null && this.book_instance.book.bookshelf_sku != null) return false;
      // Not if we already have the page count
      if (this.book_instance.number_of_pages != null) return false;
      return true;
    },
    modalTitle() {
      if (this.book_instance.id > 0) return this.$t('modals.logReading.addSteps');
      if (this.book_instance.book == null) return this.$t('modals.logReading.addToLogbook');
      return this.$t('modals.logReading.tellUs');
    }
  },
  methods: {
    required(fieldName) {
      return this.errors[fieldName] != null ? 'invalid' : '';
    },
    resetForm() {
      let today = new Date().toISOString().split('T')[0];
      // Object.assign to keep the proxy ref
      Object.assign(this.formData, {
        title: null,
        author: null,
        isbn: null,
        number_of_pages: null,
        steps: 0,
        entry_date: today,
        book_id: null,
        book_instance_id: null,
        page_number: null
      });
    },
    saveForm() {
      this.errors = {};
      if (this.formData.steps == null) {
        this.errors.steps = this.$t('modals.logReading.stepsRequired');
      }

      // If a book wasn't passed in, we need to validate a few other fields.
      if (this.book_instance.book == null) {
        if (this.formData.title == null) {
          this.errors.title = this.$t('modals.logReading.titleRequired');
        }
        if (this.formData.author == null) {
          this.errors.author = this.$t('modals.logReading.authorRequired');
        }
      }

      if (this.errors.length > 0) return;

      this.isLoading = true;

      logService.addBook(this.formData).then(() => {
        eventBus.$emit('loggedReading');
        this.closeDialog();
      }).finally(() => {
        this.isLoading = false;
      });
    },
    prePopulateForm() {
      this.resetForm();
      this.formData.title = this.book_instance.title || '';
      this.formData.author = this.book_instance.author || '';
      this.formData.isbn = this.book_instance.isbn || '';
      this.formData.book_instance_id = this.book_instance.id || null;
    },
    openDialogForNewBookInstance(book) {
      if (book != null) {
        this.book_instance = {
          title: book.title,
          author: book.author,
          isbn: book.isbn,
          book_id: book.id,
          book: book
        }
      } else {
        this.book_instance = {
          title: '',
          author: '',
          isbn: '',
          book_id: null,
          book: null
        }
      }

      this.prePopulateForm();

      this.dialog = new Modal(this.$refs.enterBookModal);
      this.dialog.show();
    },
    openDialogForExistingBookInstance(book_instance) {
      this.book_instance = book_instance;
      this.prePopulateForm();
      this.dialog = new Modal(this.$refs.enterBookModal);
      this.dialog.show();
    },

    closeDialog() {
      if (this.dialog)
        this.dialog.hide();
    }
  }
}
