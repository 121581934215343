
    import LevelBadge from '../LevelBadge.vue'
    import BookCover from '@/app/components/BookCover.vue'
    import Carousel from "@/core/components/Carousel.vue";
    import {nextTick} from 'vue';
    import eventBus from "@/core/services/eventBus";
    import bookService from "@/app/services/bookService";
    import PersistentStorage from "@/core/store/PersistentStorage";

    let open = true;

    export default {
        components: {LevelBadge, BookCover, Carousel},
        props: ['title', 'backgroundColor', 'bookRouteBooks', 'badge'],
        data() {
            return {
                /* Default Carousel State */
                carouselDisplay: 'flex',
                arrowDirection: 'none',
                borderRadius: '15px 15px 0 0',
            }
        },
        computed: {
            bookCount(): number {
                return this.books ? this.books.length : 0;
            }
        },
        methods: {
            expand() {
                if (open == false) {
                    open = true;
                    this.carouselDisplay = 'flex';
                    this.arrowDirection = 'none';
                    this.borderRadius = '15px 15px 0 0';
                } else {
                    open = false;
                    this.carouselDisplay = 'none';
                    this.arrowDirection = 'rotate(-90deg)';
                    this.borderRadius = '15px';
                }
            },
            formatTitle(title){
                return bookService.formatTitle(title);
            },
            openBook(book) {
                if (book.bookshelf_sku && bookService.canLinkToBookshelf(book.bookshelf_sku)) {
                    nextTick(() => {
                        this.input = '';
                        this.filteredItems = [];
                        eventBus.$emit("bookshelfBook", book);
                    });
                } else {
                    nextTick(() => {
                    this.input = '';
                    this.filteredItems = [];
                    eventBus.$emit("logReading", book);
                });
                }
            },
            slideTo(slide) {
                this.currentSlide = slide;
                this.$refs.carousel.slideTo(slide);
            }
        }
    }
