
import BookCover from "@/app/components/BookCover.vue";
import LevelBadge from "@/app/components/LevelBadge.vue";
import moment from 'moment-with-locales-es6';
import eventBus from "@/core/services/eventBus";
import logService from "@/app/services/LogService";
import StepsRead from "@/app/components/StepsRead.vue";
import BookRating from "@/app/components/BookRating.vue";
import bookService from "@/app/services/bookService";

export default {
  props: ['book', 'hideStats', "index", "bookCount"],
  components: {
    BookRating,
    StepsRead,
    BookCover,
    LevelBadge,
  },
  computed: {
    bookshelfURL() {
      return bookService.bookshelfURL(this.book?.book?.bookshelf_sku);
    },
    hasAccess() {
      return bookService.canLinkToBookshelf(this.book?.book?.bookshelf_sku)
    },
    readingLevel() {
      return bookService.readingLevel(this.book.book, this.$i18n.locale);
    },
    stepsRead() {
      return this.book != null ? this.book.total_steps : 0;
    },
    lastReadText() {
      if (this.book.log_entries.length == 0) return '';
      var readAt = moment(this.book.log_entries[0].entry_date).startOf('day');

      readAt.locale(this.$i18n.locale);
      return readAt.calendar({
        sameDay: this.$t('moment.today'),
        nextDay: this.$t('moment.tomorrow'),
        nextWeek: this.$t('moment.nextWeek'),
        lastDay: this.$t('moment.yesterday'),
        lastWeek: this.$t('moment.lastWeek'),
        sameElse: this.$t('moment.default')
      });
    },
    bookProgress() {
      let lastPageRead = 0;
      this.book.log_entries.map((item, index) => {
        if (item.page_number > lastPageRead) {
          lastPageRead = item.page_number
        }
        return;
      })
      return {
        progress: Math.ceil((lastPageRead / this.book.number_of_pages) * 100),
        pageLastRead: lastPageRead,
      }
    },
    isBSBook() {
      /*
       * TODO: AR-88 - make this function return true for book instances imported from arc bookshelf and false otherwise
       */
      if (!this.book || !this.book.book) return false;
      if (this.book.book.bookshelf_sku) {
        return true
      } else {
        return false
      }
    },
    showAddStepsBttn() {
      if (this.book.status == 'Paused' || this.book.status == 'Complete') {
        return false;
      } else if (this.isBSBook === false){
        return true;
      } else if (this.isBSBook === true && !this.hasAccess) {
        return true;
      } else return false;
    }
  },
  methods: {
    bookRated() {
      logService.saveBook(this.book);
    },
    viewStepLog() {
      eventBus.$emit("viewLog", this.book);
    },
    setStatus(status) {
      logService.setStatus(this.book, status);
      if (status == 'Complete') {
        eventBus.$emit("readingComplete", this.book);
      } else if (status == 'Paused') {
        eventBus.$emit("readingPaused", this.book);
      }
    },
    formatTitle(title) {
      return bookService.formatTitle(title);
    },
    addSteps(book) {
      eventBus.$emit("logReading", book);
    },
    editBook(book) {
      eventBus.$emit("editBook", book)
    },
    coverImageURL(book) {
      return bookService.coverImage(book);
    },
    openBookInBS() {
      window.open(this.bookshelfURL);
    },
  },
}
