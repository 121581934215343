

import {computed} from 'vue'

export default {
  props: {
    'modelValue': Number,
  },
  data() {
    return {
      hoverRating: null,
      range: [1, 2, 3, 4, 5],
      editMode: false
    }
  },
  methods: {
    setRating(i) {
        this.rating = i;
        this.$emit('selected', this.rating);
    },
    mouseOver(i) {
      this.hoverRating = i;
      this.editMode = true;
    },
    mouseOut() {
      this.hoverRating = 0;
      this.editMode = false;
    },
    cssClass(i) {
      let displayRating = (this.editMode) ? this.hoverRating : Math.round(this.rating);
      if (displayRating >= i) return "on";
      if (displayRating >= i && this.editMode) return "empty";
      return "off";
    }
  },
  setup(props, {emit}) {
    const rating = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    return {
      rating,
    }
  }
}
